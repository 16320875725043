/* tailwind css の設定 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ライトテーマ */
.markdown {
  font-family: "Montserrat", sans-serif;
  @apply text-base;
  word-wrap: break-word;
}

.markdown p,
.markdown blockquote,
.markdown ul,
.markdown ol,
.markdown dl,
.markdown table,
.markdown pre,
.markdown details {
  margin-top: 0;
}

.markdown a {
  @apply text-primary;
}

.markdown h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 39px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.markdown h2 {
  @apply text-xl font-semibold sm:mb-4 sm:mt-8 md:mb-5 md:mt-10;
}

.markdown h2 > a {
  @apply text-foreground;
}

.markdown h2 > a:hover {
  text-decoration: none;
}

.markdown h3 {
  @apply text-lg font-semibold sm:mb-3 sm:mt-5 md:mb-3 md:mt-6;
}

.markdown p {
  margin-bottom: 4px;
}

.markdown pre {
  padding: 16px;
  overflow-x: auto;
}

.markdown li {
  margin: 0.2em;
  @apply text-medium;
}

.markdown li > a {
  text-decoration: underline;
  font-weight: semi-bold;
}

.markdown ul {
  list-style-type: disc;
  padding-left: 20px;
}

.markdown ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.markdown ul > li {
  margin: 0.4em;
  @apply text-medium;
}

.markdown pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 16px;
  overflow: auto;
  line-height: 1.45;
  background-color: #30363d;
  border-radius: 8px;
  color: #dfe2e5;
}

.markdown pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.markdown blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.markdown img {
  margin: 1em 0;
  border-radius: 8px;
}

.tableofcontents {
  @apply text-foreground;
}

.markdown table {
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown th,
.markdown td {
  border: 1px solid #dfe2e5;
  padding: 6px 13px;
}

.markdown th {
  font-weight: 600;
  background-color: transparent;
}

.markdown tr:nth-child(2n) {
  background-color: transparent;
}

/* reCAPTCHAバッジ */
.grecaptcha-badge {
  display: none;
}
